
.App{
  height: 100vh;
  background-color: #F8F9FA !important;
}

.social-link {
  width: 30px;
  height: 30px;
  border: 1px solid #ddd;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #666;
  border-radius: 50%;
  transition: all 0.3s;
  font-size: 0.9rem;
}

.social-link:hover,
.social-link:focus {
  background: #ddd;
  text-decoration: none;
  color: #555;
}
.addresstitle{
  color:#CD2E3A;
  font-weight: bold;
}
address {
 color:rgb(49, 43, 43) 
}
@media screen and (max-width: 680px) {
  .companyImage {
    width: 360px;
  }
}
.footer{
  margin-top: 7rem;
}